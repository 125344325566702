/** @jsx jsx */
import {
  jsx,
} from 'theme-ui';
import Layout from 'components/layout';
import { Header1 } from 'components/primitives/text';
import ContactPageForm from 'components/forms/contactPageForm';
import { graphql } from 'gatsby';
import SiteMeta from 'content/meta/companyInfo.yaml';

const ContactPage = ({
  data: {
    pageContent,
  },
}: {
  data: {
    pageContent: {
      html: string;
      frontmatter: {
        title: string;
      };
    };
  };
}) => (
  <Layout title="Kontakt">
    <div
      sx={{
        display: 'grid',
        gridGap: [0, 0, 4],
        pb: [0, 0, 0, 0, 6],
        gridTemplateColumns: [
          [
            'auto',
            '1fr',
          ], [
            'auto',
            '1fr',
          ], [
            'auto',
            '1fr',
          ], [
            'auto',
            '35% 1fr',
          ],
        ],
      }}
    >
      <div>
        <Header1>{pageContent?.frontmatter.title}</Header1>
        <div
        // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: pageContent.html }}
          sx={{ mb: 6 }}
        />
        <div sx={{ fontSize: 2 }}>
          <div><a href={`https://maps.google.com?q=${SiteMeta.address}`}>{SiteMeta.address}</a></div>
          <div><a href={`tel:${SiteMeta.phone}`}>{SiteMeta.phone}</a></div>
          <div><a href={`mailto:${SiteMeta.phone}`}>{SiteMeta.email}</a></div>
        </div>
      </div>
      <div>
        <ContactPageForm />
      </div>
    </div>
  </Layout>
);

export default ContactPage;

export const pageQuery = graphql`
query ContactQuery {
  pageContent: markdownRemark(fileAbsolutePath: {regex: "/pages.*\/contact.md$/"}) {
    id
    html
    frontmatter {
      title
    }
  }
}
`;
