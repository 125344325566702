/** @jsx jsx */
import { useState } from 'react';
import {
  jsx, Flex, Label, Input, Textarea,
} from 'theme-ui';
import { PrimaryButton } from 'components/layout/button';
import { encode } from 'utils/helper';
import { HighlightText, Header3, Header2 } from 'components/primitives/text';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
// import Recaptcha from 'react-google-recaptcha';


const ThankYou = () => (
  <Flex
    sx={{
      py: 6,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Header2>
      Tack! <span role="img" aria-label="thank-you">🙏</span>
    </Header2>
    <p>Dina uppgifter har skickats.</p>
  </Flex>
);

interface FormInputs {
  'form-name': string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  text: string;
  // 'g-recaptcha-response': string;
}

const schema = yup.object().shape({
  firstName: yup.string().required('Obligatoriskt'),
  email: yup.string().email('Ogiltig epostadress').required('Obligatoriskt'),
  text: yup.string(),
  // 'g-recaptcha-response': yup.string().required('Obligatoriskt'),
});

const ContactPageForm = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const handleRecaptcha = (value: any) => {
  //   setValue('g-recaptcha-response', value);
  // };


  const onSubmit = (data: FormInputs) => {
    fetch('/', {
      method: 'POST',
      body: encode(data),
    }).then(() => setSubmitted(true));
  };


  return (
    <Flex
      sx={{
        flexDirection: 'column',
        color: ['white', 'white', 'text'],
        m: ['0 auto'],
        backgroundColor: 'transparent',
        borderRadius: 'default',
        mt: [5, 5, 6, 3],
      }}
    >
      <Header3 sx={{ textAlign: 'center', color: 'primary' }}>
        <HighlightText>{'{{Kontaktformulär}}'}</HighlightText>
      </Header3>
      {
      isSubmitted
        ? <ThankYou />
        : (
          <form
            name="Kontaktformulär (kontaktsida)"
            method="post"
            action="/"
            netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p
              sx={{
                visibility: 'hidden',
                lineHeight: 0,
                margin: 0,
                height: 0,
              }}
            >
              <label htmlFor="bot-field">Don’t fill this out if you&apos;re human: <input name="bot-field" /></label>
            </p>
            <noscript>
              <p>This form won’t work with Javascript disabled</p>
            </noscript>
            <input ref={register} type="hidden" name="form-name" value="Kontaktformulär (kontaktsida)" />
            <p sx={{
              textAlign: 'right',
              fontSize: 1,
              mb: 0,
              color: 'muted',
              fontWeight: 'body',
            }}
            >
              * Obligatoriskt fält
            </p>
            <div
              sx={{
                display: 'grid',
                gridGap: [0, 0, 4],
                gridTemplateColumns: [
                  [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr 1fr',
                  ],
                ],
              }}
            >
              <div>
                <Label htmlFor="firstName">Förnamn *</Label>
                <Input
                  type="text"
                  name="firstName"
                  className={classNames({ 'has-error': !_.isUndefined(errors.firstName) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.firstName?.message}</p>
              </div>

              <div>
                <Label htmlFor="lastName">Efternamn</Label>
                <Input
                  type="text"
                  name="lastName"
                  className={classNames({ 'has-error': !_.isUndefined(errors.lastName) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.lastName?.message}</p>
              </div>
            </div>
            <div
              sx={{
                display: 'grid',
                gridGap: [0, 0, 4],
                gridTemplateColumns: [
                  [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr 1fr',
                  ],
                ],
                alignContent: 'center',
              }}
            >
              <div>
                <Label htmlFor="email">E-postadress *</Label>
                <Input
                  type="text"
                  name="email"
                  className={classNames({ 'has-error': !_.isUndefined(errors.email) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.email?.message}</p>
              </div>
              <div>
                <Label htmlFor="phone">Telefon</Label>
                <Input
                  type="text"
                  name="phone"
                  className={classNames({ 'has-error': !_.isUndefined(errors.phone) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.phone?.message}</p>
              </div>
            </div>
            <Label htmlFor="text">Beskriv ert intresse</Label>
            <Textarea
              name="text"
              rows={5}
              ref={register}
            />
            <div
              sx={{
                display: 'grid',
                gridGap: [0, 0, 4],
                gridTemplateColumns: [
                  [
                    'auto',
                    '1fr',
                  ],
                  [
                    'auto',
                    '1fr',
                  ],
                  [
                    'auto',
                    '1fr',
                  ],
                  [
                    'auto',
                    '1fr',
                  ],
                  [
                    'auto',
                    '1fr',
                  ],
                ],
                alignContent: 'center',
              }}
            >
              {/* <Recaptcha
                sitekey="6LcHsq4ZAAAAAEMWxZFdCHRPVswN9KAxFiaXMz-W"
                onChange={handleRecaptcha}
                sx={{ m: '0 auto', mt: 4, mb: 0 }}
              /> */}
              <div>
                <PrimaryButton
                  icon="paper-plane"
                  sx={{ m: '0 auto', my: 4, float: ['none', 'none', 'right'] }}
                  type="submit"
                >
                  Skicka
                </PrimaryButton>
              </div>
            </div>
          </form>
        )
      }
    </Flex>
  );
};

export default ContactPageForm;
